import { defineMessages, IntlShape } from 'react-intl'

import { getRegionKeywords } from '@app/utils/routing/region'

import { LandingVersion } from '@app/store/selectors/layout'
import { profileRegionSelector } from '@app/store/selectors/profile'

type Region = ReturnType<typeof profileRegionSelector>

export interface LandingVersionDescriptor {
  /** used in page tittle and langing header */
  meta_title: string
  /** used in page tittle and langing header */
  title: string
  /** used in intro header */
  tags: string[]
  /** meta description */
  description: string
  /** meta keywords */
  keywords: string
  images: string[]

  app_title: string
  app_subtitle?: string

  sitter_can_title: string
  sitter_can: string[]
}

export type LandingIntroVersionGetter = (c: Region) => LandingVersionDescriptor

export type LandingIntroVersionData = { [key in LandingVersion]: LandingIntroVersionGetter }

export class LandingData {
  constructor(
    private readonly formatMessage: IntlShape['formatMessage'],
    private region: Region,
    private version: LandingVersion
  ) {}

  /** Data for LandingParent.renderIntro */
  getData(): LandingVersionDescriptor {
    const slug = this.region.attributes.slug
    const locative = slug === 'world' ? this.formatMessage(messages.locative_everywhere) : this.region.attributes.prepositional_name
    const keywords = this.getKeywords()
    const images = this.getImages()
    const app_title = this.getAppTitle()
    const app_subtitle = this.getAppSubtitle()
    const sitter_can_title = this.getSitterAbilitiesTitle()
    const sitter_can = this.getSitterAbilities()

    return {
      meta_title: this.formatMessage(this.getMetaTitleMessage(), { locative }),
      title: this.formatMessage(this.getTitleMessage(), { locative }),
      tags: this.formatMessage(messages[`tags_${this.version}`] ?? tags[slug] ?? messages.make_homework).split('\n'),
      description: this.formatMessage(this.getDescriptionMessage()),
      images,
      keywords,
      app_title,
      app_subtitle,
      sitter_can_title,
      sitter_can,
    }
  }

  private getMetaTitleMessage() {
    const slug = this.region.attributes.slug
    return (
      messages[`meta_title_${this.version}_${slug}`] ??
      messages[`meta_title_${this.version}`] ??
      messages[`meta_title_main_${slug}`] ??
      messages['meta_title_main'] ??
      this.getTitleMessage()
    )
  }

  private getTitleMessage() {
    const slug = this.region.attributes.slug
    return (
      messages[`title_${this.version}_${slug}`] ??
      messages[`title_${this.version}`] ??
      titles[slug] ??
      messages[`title_main_${slug}`] ??
      messages.kidsout_babysitters_cool_and_reliable
    )
  }

  private getDescriptionMessage() {
    const slug = this.region.attributes.slug
    return (
      messages[`description_${this.version}_${slug}`] ??
      messages[`description_${this.version}`] ??
      messages[`description_main_${slug}`] ??
      messages.description_main
    )
  }

  private getImages() {
    switch (this.version) {
      case 'camp': {
        return [require('../../assets/landing-camp-background.webp'), require('../../assets/landing-camp-background.jpg')]
      }
      case 'online': {
        return [require('../../assets/landing-online-background.webp'), require('../../assets/landing-online-background.jpg')]
      }
      case 'main':
      case 'urgent_nanny':
      case 'part_time_nanny':
      case 'coolest':
      case 'safety':
      case 'nanny':
      default:
        return [require('../../assets/landing-sitter-background_v2.webp'), require('../../assets/landing-sitter-background_v2.jpg')]
    }
  }

  private getAppTitle() {
    if (this.isCapital()) return this.formatMessage(messages.app_title_2)
    return this.formatMessage(messages.app_title)
  }

  private getAppSubtitle() {
    if (!this.region) return undefined
    if (messages[`app_subtitle_${this.region.attributes.slug}`]) return this.formatMessage(messages[`app_subtitle_${this.region.attributes.slug}`])
    return undefined
  }

  private getKeywords(): string {
    const keywords = this.getRegionKeywords()
    if (messages[`keywords_${this.version}`]) return [keywords, this.formatMessage(messages[`keywords_${this.version}`])].join(', ')
    return keywords
  }

  private getRegionKeywords(): string {
    if (!this.region) return this.formatMessage(messages.keywords_default)
    const locative = this.region.attributes.prepositional_name.toLocaleLowerCase()
    const regionKeywords = getRegionKeywords(this.region.attributes.slug)
    const keywords = this.formatMessage(messages.keywords_locative_start)
      .split(', ')
      .concat(
        this.formatMessage(messages.keywords_locative)
          .split(', ')
          .map(keyword => this.formatMessage(messages.keywords_locative_join, { keyword, locative })),
        regionKeywords
      )
      .join(', ')

    return keywords
  }

  private getSitterAbilitiesTitle(): string {
    if (this.version === 'nanny') return this.formatMessage(messages.sitter_can_title_nanny)
    if (this.version === 'online') return this.formatMessage(messages.sitter_can_title_online)
    if (this.isCapital()) return this.formatMessage(messages.sitter_can_title_capitol)
    return this.formatMessage(messages.sitter_can_title_default)
  }

  private getSitterAbilities(): string[] {
    if (this.isCapital())
      return this.formatMessage(messages.sitter_can_capitol)
        .trim()
        .split('\n')
        .map(s => s.trim())
    if (messages[`sitter_can_${this.version}`])
      return this.formatMessage(messages[`sitter_can_${this.version}`])
        .trim()
        .split('\n')
        .map(s => s.trim())
    return this.formatMessage(messages.sitter_can)
      .trim()
      .split('\n')
      .map(s => s.trim())
  }

  private isCapital() {
    return this.region.attributes.slug === 'msk' || this.region.attributes.slug === 'spb'
  }
}

const tags = defineMessages({
  kld: 'Собирать янтарь и любоваться закатами, пока с ребёнком ситтер',
  krasnoyarsk: 'Хайкинг любой сложности в Гремячьей гриве и на Торгашинском хребте, пока дети с ситтером',
  world: 'Всё успевать с детьми',
  msk: 'Всё успевать с детьми',
  nn: 'Смотреть закаты в Нижнем, а ситтер позаботится о ребенке',
  nsk: 'Всё успевать с детьми',
  oren: 'Теперь у вашей бабушки будет время вязать пуховый платок!',
  spb: 'Наслаждаться белыми ночами, а ситтер позаботится о ребенке',
  russia: 'Ответственный присмотр за детьми',
  rnd: 'Вы можете чистить раков двумя руками, пока дети с бебиситтером',
  sochi: 'Отдохнуть смогут и дети и родители',
  tomsk: 'Лайфхаки работающих родителей',
  tmn: 'Нежиться в горячих источниках, пока с ребёнком ситтер',
})

const titles = defineMessages({
  ekat: 'Бебиситтеры и няни Kidsout проверенные и классные',
  kld: 'Бебиситтеры и няни Kidsout классные и надёжные',
  krasnoyarsk: 'Бебиситтеры и няни Kidsout обученные и классные',
  world: 'Бебиситтеры и няни Kidsout проверенные и активные',
  msk: 'Kidsout: проверенные и классные бебиситтеры и няни ',
  nn: 'Бебиситтеры и няни Kidsout обученные и активные',
  nsk: 'Kidsout: проверенные и классные бебиситтеры и няни на час',
  oren: 'Kidsout: проверенные и активные бебиситтеры и няни на час',
  spb: 'Kidsout: надёжные и классные бебиситтеры и няни',
  russia: 'Проверенные няни на час и бебиситтеры Kidsout',
  rnd: 'Kidsout: обученные и классные бебиситтеры и няни',
  sochi: 'Kidsout: проверенные бебиситтеры в отпуске',
  tomsk: 'Обученные няни на час и бебиситтеры только в Kidsout',
  tmn: 'Kidsout: проверенные и классные бебиситтеры и няни на час',
})

const messages = defineMessages({
  locative_everywhere: 'любой точке мира',

  keywords_default: 'kidsout, кидзаут, кидсаут, няня срочно, ищете няню срочно, няня на час, няня почасовая, няня почасовая оплата',
  keywords_locative_start: 'kidsout, кидзаут, кидсаут',
  keywords_locative: 'няня срочно, ищете няню срочно, няня на час, няня почасовая, няня почасовая оплата',
  keywords_locative_join: '{keyword} в\u00a0{locative}',

  meta_title_main_msk: 'Лучшие бебиситтеры и няни для ваших детей в Москве — Kidsout',
  meta_title_main_spb: 'Лучшие бебиситтеры и няни для ваших детей в СПБ — Kidsout',
  meta_title_main: 'Бебиситтеры Kidsout — классные и надежные бебиситтеры в {locative}',

  kidsout_babysitters_cool_and_reliable: 'Бебиситтеры Kidsout классные и\u00a0надежные',

  make_homework: 'Сделать домашнее задание\nВместе читать\nИграть в\u00a0морской бой\nВыдумать новый мир',
  description_main: 'Проверенные и обученные онлайн-бебиситтеры и няни на час. Спокойно работайте из дома. Ребёнку будет классно.',
  description_main_msk:
    'Ищете хорошую няню для ребенка в Москве? Проверенные и обученные онлайн-бебиситтеры и няни на час. Простой и удобный сервис, чтобы быстро и без посредников найти няню — Kidsout.',
  description_main_spb:
    'Проверенные и обученные бебиситтеры-онлайн и няни на час в Санкт-Петербурге. Услуги няни на час без посредников. Отличное решение для тех, кому няня нужна на час-два-три и более часов. Переходите на сайт kidsout.ru/spb',

  keywords_main: 'бебиситтер онлайн, онлайн-бебиситтеры',

  title_urgent_nanny: 'Бебиситтер и\u00a0няня в\u00a0{locative} прямо сейчас',
  tags_urgent_nanny: 'Няня заболела\nРебенок не идет в\u00a0сад\nСрочная работа\nНужно срочно уйти',
  description_urgent_nanny: 'Срочно нужна няня? бебиситтер? В {locative} Kidsout.ru поможет вам! Закажите сейчас и через 2 часа няня будет у вас дома.',

  title_part_time_nanny: 'Бебиситтер и\u00a0няня на\u00a0несколько часов в\u00a0{locative}',
  tags_part_time_nanny: 'Выйти погулять\nСводить в\u00a0музей\nСходить в\u00a0кино\nОтпустить в\u00a0гости',
  description_part_time_nanny: 'Бебиситтер и\u00a0няня на\u00a0несколько часов в\u00a0{locative}. Займитесь своими делами, ребёнку будет классно.',

  title_safety: 'Проверенные и\u00a0обученные бебиситтеры и\u00a0няни на\u00a0час в\u00a0{locative}',
  tags_safety: 'Занимаюсь своими делами\nДети под\u00a0присмотром\nДетям классно',
  description_safety: 'Проверенные и обученные бебиситтеры и няни на час в {locative}. Займитесь своими делами, ребёнку будет классно.',

  title_nanny: 'Проверенные и\u00a0обученные няни на\u00a0час в\u00a0{locative}',
  tags_nanny: 'Занимаюсь своими делами\nДети под\u00a0присмотром\nДетям классно',
  description_nanny: 'Проверенные и обученные няни на час в {locative}. Займитесь своими делами, ребёнку будет классно.',

  title_coolest: 'Самые классные бебиситтеры и\u00a0няни в\u00a0{locative}',
  tags_coolest: 'Сыграть в футбол\nПровести экскурсию\nИграть три часа в прятки\nВыдумать новый мир',
  description_coolest:
    'Только обученные и проверенные бебиситтеры и няни на час в {locative}. В Kidsout более 1 900 проверенных нянь с почасовой оплатой от 18 до 60 лет, с опытом до 4000 часов и реальными отзывами в {locative}.',

  title_camp: 'Классные и\u00a0весёлые бебиситтеры и\u00a0няни на\u00a0лето в\u00a0{locative}',
  tags_camp: 'Много гулять\nИграть и\u00a0развиваться\nПосещать музеи\nКататься на велах',
  description_camp:
    'Только обученные и проверенные бебиситтеры и няни на час в {locative}. В Kidsout более 1 900 проверенных нянь с почасовой оплатой от 18 до 60 лет, с опытом до 4000 часов и реальными отзывами в {locative}.',

  title_online: 'Бебиситтеры\u00a0онлайн\u00a0– классные и\u00a0надёжные бебиситтеры в\u00a0любой точке мира',
  tags_online: 'Сделать домашнее задание\nВместе читать\nИграть в\u00a0морской бой\nВыдумать новый мир',
  description_online: 'Проверенные и\u00a0обученные онлайн-бебиситтеры и\u00a0няни на\u00a0час. Спокойно работайте из\u00a0дома. Ребёнку будет классно.',
  keywords_online: 'бебиситтер онлайн, онлайн-бебиситтеры',

  app_title: 'Установите наше приложение',
  app_title_2: 'Установите приложение',
  app_subtitle_msk: 'Так поиск бебиситтера и\u00a0няни для ребёнка в\u00a0Москве будет ещё удобнее',
  app_subtitle_spb: 'Поиск бебиситтера и\u00a0няни для ребёнка в\u00a0Санкт-Петербурге без посредников',

  sitter_can_title_online: 'Ситтер онлайн может помочь',
  sitter_can_title_nanny: 'Няня может',
  sitter_can_title_capitol: 'Kidsout поможет, если нужно',
  sitter_can_title_default: 'Бебиситтер может',

  sitter_can: `
    Посидеть с\u00a0ребенком, пока вас нет дома
    Отвезти ребенка на занятия, к\u00a0репетитору, в\u00a0спортивную секцию
    Проводить ребенка домой из\u00a0школы или сада
    Помочь с\u00a0детьми на\u00a0дне рождения, семейном празднике
    Отвлечь ребенка, пока вы\u00a0заняты домашними делами
    Развлечь ребенка в\u00a0торговом или\u00a0спортивном центре, пока вы заняты
    Отвезти ребенка к\u00a0бабушкам или\u00a0другим родственникам
    Поиграть с\u00a0ребенком в\u00a0футбол, сходить в\u00a0музей или на\u00a0лекцию
  `,

  sitter_can_capitol: `
    Посидеть с\u00a0ребенком, пока вас нет дома
    Отвезти ребенка на\u00a0занятия, к\u00a0репетитору, в\u00a0спортивную секцию
    Проводить ребенка домой из\u00a0школы или сада
    Помочь с\u00a0детьми на\u00a0дне рождения, семейном празднике
    Отвлечь ребенка, пока вы\u00a0заняты домашними делами
    Развлечь ребенка в\u00a0торговом или спортивном центре, пока вы\u00a0заняты
    Отвезти ребенка к\u00a0бабушкам или другим родственникам
    Поиграть с\u00a0ребенком в\u00a0футбол, сходить в\u00a0музей или на\u00a0лекцию
    Срочно найти няню или\u00a0бебиситтера
    Присмотреть за\u00a0детьми несколько часов
  `,

  sitter_can_online: `
    Родителям, которые работают из\u00a0дома с детьми
    Детям на\u00a0больничном, даже тем, кто лежит в\u00a0больнице.
    Постоянным клиентам, которые уехали на\u00a0дачу, загород, в\u00a0командировку вместе с\u00a0детьми.
    Клиентам с\u00a0детьми старше 4\u00a0лет, которых уже можно увлечь игрой от 1\u00a0часа.
    Семьям, которым срочно нужно занять ребёнка здесь и\u00a0сейчас
    Если в\u00a0вашем городе и\u00a0стране пока нет офлайн ситтеров Kidsout.
  `,
})
